

.contact__form input.form-control {
    margin-bottom: 2em;
    height: calc(2.5em + .75rem + 2px);
}

button.btn.ac_btn:hover {
    color: var(--secondary-color-2);
}
.con_header h1 {
    color: var(--text-color)
}
.loading-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    z-index: 999999999;
    background: var(--text-color);
    transform: translateX(100%);
    animation: shift-rightwards 1s ease-in-out infinite;
    animation-delay: .3s;
}
p, span{
    color: var(--text-color)
}
@keyframes shift-rightwards {
    0% {
        transform: translateX(-100%);
    }
    40% {
        transform: translateX(0%);
    }
    60% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}

.themed-form {
    background-color: var(--bg-color); 
}

.themed-form .form-control {
    background-color: var(--bg-color);
    color: var(--text-color);
}