
  

.site__header {
    top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background-color:  var(--bg-color);
}
.menu__button .nav_ac{
    padding-bottom: 3px;
}

.menu__button {
    color: var(--text-color);
}

.menu__button:focus,
.menu__button:hover {
    color: var(--text-color);
    box-shadow: unset;
}

.menu__button svg {
    width: 2em;
    height: 2em;
    fill: var(--text-color);
    color: var(--text-color);
}

.nav_ac {
    padding: 8px 15px;
    margin: 0;
    border: unset;
    background: var(--primary-color);
    font-size: 1rem;
    font-family: Roboto;
    font-weight: bold;
    letter-spacing: 2.6px;
    color: var(--text-color);
    line-height: 2;
    height: 50px;
    z-index: 1000;
}

.nav_ac:hover {
    color: var(--text-color);
}

.br-top,
.br-bottom,
.br-right,
.br-left {
    position: fixed;
    z-index: 999999;
    background: var(--primary-color);
}

.br-top {
    top: 0;
    height: 10px;
    left: 0;
    width: 100%;
}

.br-bottom {
    bottom: 0;
    left: 0;
    height: 10px;
    width: 100%;
}

.br-right {
    width: 10px;
    right: 0;
    top: 0;
    height: 100%;
}

.br-left {
    width: 10px;
    left: 0;
    top: 0;
    height: 100%;
}

.cortina__wrapper-menu {
    position: relative;
    width: 100%;
    padding-top: 5em;
    padding-bottom: 3em;
    height: 100%;
    overflow-y: auto;
}

.site__navigation {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    visibility: hidden;
}

.menu__opend {
    visibility: visible !important;
}

.main__menu_ul,
.menu_right {
    opacity: 0;
    position: relative;
    transition: 0.5s;
    transition-delay: 0s;
    visibility: hidden;
    z-index: 100;
}

.menu_right {
    text-align: center;
}

.site__navigation.menu__opend .main__menu_ul,
.site__navigation.menu__opend .menu_right {
    opacity: 1;
    transition-delay: 0.6s;
    visibility: visible;
}

.site__navigation .main__menu_ul li {
    list-style: none;
    margin: 10px 0;
}

.site__navigation .main__menu_ul li a {
    color: var(--text-color);
    display: block;
    font-size: 2.5rem;
    text-decoration: none;
}

.bg__menu {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    will-change: transform;
    transform: translateY(-100%);
    transition: .5s ease all;
}

.menu__opend .bg__menu {
    transform: translateY(0);
}

.menu__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden auto;
}

.the_menu {
    padding-top: 10vh;
    padding-bottom: 20vh;
    padding-left: 0;
}

@media (min-width: 992px) {
    .menu__container {
        margin-left: 0%;
        width: 100%;
        height: 100%;
    }
    .the_menu {
        padding-top: 20vh;
        padding-bottom: 30vh;
        height: 100%;
    }
}

.the_menu .menu_item>a {
    color: var(--text-color-2);
    line-height: 1;
    font-size: large;
    font-family: Roboto;
    font-weight: 600;
    display: inline;
    position: relative;
    transition: color 250ms cubic-bezier(0, 0, 0.58, 1) 0s;
    padding: 4px 0px;
    text-decoration: none;
    font-family: Roboto;
}
.the_arrow {
    width: 56px;
    transition: all 0.2s;
}
.the_arrow {
    position: absolute;
    top: 50%;
    left: 0;
}
.the_arrow > .shaft {
    width: 0;
    background-color: var(--text-color);
}
.the_arrow > .shaft:before, .the_arrow > .shaft:after {
    width: 0;
    background-color: var(--text-color);
}
.the_arrow > .shaft:before {
    transform: rotate(0);
}
.the_arrow > .shaft:after {
    transform: rotate(0);
}

.the_arrow > .shaft {
    background-color: var(--text-color);
    display: block;
    height: 1px;
    position: relative;
    transition: all 0.2s;
    transition-delay: 0;
    will-change: transform;
}
.the_arrow > .shaft:before, .the_arrow > .shaft:after {
    background-color: var(--text-color);
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.2s;
    transition-delay: 0;
}
.the_arrow > .shaft:before {
    transform-origin: top right;
}
.the_arrow > .shaft:after {
    transform-origin: bottom right;
}
  
.the_menu .menu_item .my-3:hover {
    color: var(--text-color-3);
}
.the_menu .menu_item .my-3:hover > .the_arrow > .shaft {
  width: 56px;
  height: 2px;
  transition-delay: 0.1s;
  background-color: var(--text-color-3);
}
.the_menu .menu_item .my-3:hover > .the_arrow > .shaft:before, .the_menu .menu_item .my-3:hover > .the_arrow > .shaft:after {
    width: 10px;
    height: 2px;
    transition-delay: 0.1s;
    background-color: var(--text-color-3);
}
.the_menu .menu_item .my-3:hover > .the_arrow > .shaft:before {
    transform: rotate(40deg);
}
.the_menu .menu_item .my-3:hover > .the_arrow > .shaft:after {
transform: rotate(-40deg);
}
.the_menu .menu_item .my-3:hover > .main{
transform: translateX(56px);
color: var(--text-color-3);
}
.the_menu .menu_item >.my-3 .main {
display: flex;
align-items: center;
transition: all 0.2s;
}
.the_menu .menu_item >.my-3 .main > .text {
margin: 0 16px 0 0;
line-height: 1;
}
.the_menu .menu_item >.my-3 .main > .the-arrow {
position: relative;
}

.the_menu .menu_item .my-3:hover > .horizontal_line {
    background-color: var(--text-color-3);
}
@media (min-width: 768px) {
    .the_menu .menu_item>a {
        font-size: 4.8vw;
    }
}

.menu_footer {
    bottom: 0;
    font-family: Roboto;
    font-size: 14px;
    background: var(--primary-color);
}

.menu_footer a {
    color: var(--text-color-2);
    margin-right: 10px;
    text-decoration: none;
}

.horizontal_line {
    background-color: var(--text-color);
    width: 100%;
    height: 2px;
    line-height: 30%;
}

.main{
    padding:10px;
    font-family: Roboto;
    font-size: 100%;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
}