.po_items_ho {
    column-width: 15em;
    -moz-column-width: 15em;
    -webkit-column-width: 15em;
    column-gap: 4rem;
    -moz-column-gap: 4rem;
    -webkit-column-gap: 4rem;
}

.po_header h1 {
    color: var(--text-color)
}

.po_item {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0.5rem auto;
    position: relative;
    background: var(--overlay-color);
    padding: 0px;
    transition: 0.3s ease;
    font-size: 0;
    min-height: 300px;
}

.po_item_empty {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0.5rem auto;
    position: relative;
    background: var(--overlay-color);
    padding: 0px;
    transition: 0.3s ease;
    font-size: 0;
    min-height: 300px;
}

.po_item img {
    max-width: 100%;
    transition: 0.3s ease-in-out; 
}

 /* new change */
.po_item:hover img {
    filter: brightness(30%);
}

 /* new change */
.po_item .content h2 {
    position: absolute;
    font-size: 1.5rem;
    font-family: Roboto;
    font-weight: 550; 
    color: white;
    margin: 0; 
    opacity: 0;
    transition: 0.3s ease-in-out;
    text-align: center;
}

 /* new change */
.po_item:hover .content h2 {
    text-align: center;
    opacity: 1;
}

.po_item .content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.01); 
    opacity: 0;
    z-index: 1;
    transition: 0.3s ease-in-out;
    font-size: 20px;
    color: var(--secondary-color);
}


.po_item:hover {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
    /* height: calc(100% - 0px);
    width: calc(100% - 0px); */
    opacity: 1;
    /* color: var(--secondary-color); */

}

.po_item .content p {
    font-size: 1rem;
    font-family: Roboto;
    font-weight: 300; 
}

.po_item .content a, button {
    background: rgba(255, 255, 255, 0.01);
    border: rgba(255, 255, 255, 0.01);
    /* padding: 4px 8px;
    text-align: center;
    font-size: 1rem;
    font-family: Roboto;
    font-weight: 300; */
    width: 100%;
    height: 100%;
}

.po_item .content a, button:hover {
    text-decoration: none;
}


.popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 75%;
    height: 80%;
    padding: 0px;
    /* bottom: 0;
    position: absolute */
}

@media (min-height: 768px) and (orientation: portrait) {
  /* set width to 80% when screen height > screen width */
  .popup-content {
    width: 90%;
  }
}

.popup-arrow {
color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
width: 200px;
box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
background: transparent;
}
