@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;900&display=swap');
:root {
  --bg-color: #fffdf5;
  --primary-color: #fffdf5;
  --secondary-color: #353535;
  --text-color: #353535;
  --text-color-2: #353535;
  --text-color-3: #353535;
  --overlay-color: #fffdf5;
  --button-bg-color: #353535;
  --button-bg-color-2: #1c1c1c;
  --button-text-color: #fffdf5;
}

[data-theme="light"] {
  --bg-color: #fffdf5;
  --primary-color: #fffdf5;
  --secondary-color: #353535;
  --secondary-color-2: #353535;
  --text-color: #353535;
  --text-color-2: #353535;
  --text-color-3: #353535;
  --overlay-color: #fffdf5;
  --button-bg-color: #353535;
  --button-bg-color-2: #1c1c1c;
  --button-text-color: #fffdf5;
}

[data-theme="dark"] {
  --bg-color: #353535;
  --primary-color: #353535;
  --secondary-color: #353535; 
  --secondary-color-2: #fffdf5;
  --text-color: #fffdf5;
  --text-color-2: #353535;
  --text-color-3: #fffdf5;
  --overlay-color: #353535;
  --button-bg-color: #fffdf5;
  --button-bg-color-2: #faf6e6;
  --button-text-color: #353535;
}

/*
sample 
[data-theme="light"] {
  --bg-color: #ffffff;
  --primary-color: #ffffff;
  --secondary-color: #575757;
  --secondary-color-2: #575757;
  --text-color: #575757;
  --text-color-2: #575757;
  --text-color-3: #353535;
  --overlay-color: #ffffff;
  --button-bg-color: #575757;
  --button-bg-color-2: #353535;
  --button-text-color: #ffffff;
}*/


html,
body {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;
  border-left: 10px solid var(--primary-color);
  border-right: 10px solid var(--primary-color);
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto;
  font-weight: 900;

}

div {
  background-color: var(--bg-color);
}
h1{
  text-transform: uppercase;
}
a,
a:hover {
  color: var(--text-color);
}

p {
  word-break: break-word;
  hyphens: auto;
  font-family: Roboto;
  font-weight: 400;
  font-size: large
}

.ovhidden {
  overflow: hidden;
}

.text_2,
.text_2:hover {
  color: var(--text-color-2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor__dot div {
  z-index: 999999 !important;
}

.cursor__dot div:last-child {
  background-color: var(--overlay-color) !important;
}

.cursor__dot div:first-child {
  filter: invert(1);
  background-color: var(--overlay-color) !important;
}

.color_pr {
  color: var(--primary-color) !important;
}

.color_sec {
  color: var(--secondary-color);
}

:root .light-image {
  display: block;
}
:root .dark-image {
  display: none;
}

[data-theme="light"] .light-image {
  display: block;
}
[data-theme="light"] .dark-image {
  display: none;
}

[data-theme="dark"] .dark-image {
  display: block;
}
[data-theme="dark"] .light-image {
  display: none;
}
